<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-12 col-12">My Profile</div>
  </div>
  <div class="layout-box-content-section mb-3" style="height: calc(100vh - 157px)">
    <div class="row">
      <div class="col-2 mb-4">
        <div class="myprofile-avatar-outer">
          <img v-if="profileupdate.userProfileImage != null && profileupdate.userProfileImage != ''"
          :src="'https://storage.googleapis.com/' + this.hoststorageimgpath + '/host/userprofile/' + this.profileupdate.userProfileImage" class="myprofile-avatar rounded-circle" alt="" width="80" />
            
          <img v-if="profileupdate.userProfileImage == null || profileupdate.userProfileImage == ''"
            src="/assets/images/icon/customers-tab-icon.svg" class="myprofile-avatar rounded-circle" alt="" width="80" />
          <div class="custom-image-upload">
            <label for="file-input">
              <img src="/assets/images/icon/profile-img-edit.svg" alt="" v-show="disableInputBool == false" width="19" />
            </label>
            <input type="file" id="file-input" :disabled="disableInputBool" name="profileupdate.uploadavtimg"
              ref="uploadavtimg" v-on:change="handleUploadVoterProfile()" />
          </div>
        </div>
        <div class="custom-img-error mt-2" v-if="errorprofileimage">{{ errorprofileimage }}</div>
      </div>
      <div class="col-6 align-self-center text-start">
        <div v-show="disableInputBool == false">
          <div v-if="profileupdate.uploadavtimg && !errorprofileimage" class="upload-image-status-outer ">
            <div class="from-label">{{ profileupdate.uploadavtimg ? profileupdate.uploadavtimg.name : "" }}</div>
            <div class="from-label-value">Profile Image Uploaded Successfully</div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12 align-self-end text-end">
        <div class="custom-form-group">
          <button type="button" @click="profileeditbtn" class="btn custom-view-detail-btn" :disabled="disableBtnBool">
            <i class="pi pi-pencil me-2"></i>Edit
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="custom-form-group">
          <label for="formfirstnameInput" class="form-label">First Name</label>
          <div class="input-group custom-input-group">
            <div class="col-lg-4 col-md-4 col-12">
              <Multiselect v-model="profileupdate.title" :options="titleList" :searchable="false" label="label"
                placeholder="Select" class="multiselect-custom custom-title-multiselect text-capitalize" :canClear="false"
                :closeOnSelect="true" :object="true" :disabled="disableInputBool" noOptionsText="No Result"
                :canDeselect="false" />
            </div>
            <div class="col-lg-8 col-md-8 col-12">
              <input type="text" v-model="profileupdate.firstname" class="form-control text-capitalize"
                id="formfirstnameInput" placeholder="First Name" autocomplete="off" :disabled="disableInputBool"
                @keypress="isLetterWithOutSpace($event)" />
            </div>
          </div>
          <div class="custom-error" v-if="v$.profileupdate.firstname.$error">
            {{ v$.profileupdate.firstname.$errors[0].$message }}
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="custom-form-group">
          <label for="formmdnameInput" class="form-label">Middle Name</label>
          <input type="text" v-model="profileupdate.middlename" class="form-control text-capitalize" id="formmdnameInput"
            placeholder="Middle Name" autocomplete="off" :disabled="disableInputBool"
            @keypress="isLetterWithOutSpace($event)" />
        </div>
      </div>
      <div class="col-3">
        <div class="custom-form-group">
          <label for="formlastnameInput" class="form-label">Last Name</label>
          <input type="text" v-model="profileupdate.lastname" class="form-control text-capitalize" id="formlastnameInput"
            placeholder="Last Name" autocomplete="off" :disabled="disableInputBool"
            @keypress="isLetterWithOutSpace($event)" />
          <div class="custom-error" v-if="v$.profileupdate.lastname.$error">
            {{ v$.profileupdate.lastname.$errors[0].$message }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="custom-form-group">
          <label for="formemailInput" class="form-label">Email Id</label>
          <input type="text" v-model="profileupdate.email" class="form-control" id="formemailInput" placeholder="Email Id"
            autocomplete="off" :disabled="disableInputBool" />
          <!-- <div class="custom-error" v-if="v$.profileupdate.email.$error">
            {{ v$.profileupdate.email.$errors[0].$message }}
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="custom-form-group">
          <label for="formcontactInput" class="form-label">Mobile No.</label>
          <div class="input-group custom-input-group">
            <div class="col-lg-4 col-md-4 col-12">
              <Multiselect v-model="profileupdate.countrycode" :options="countryCodeList" :searchable="true" label="label"
                placeholder="Select" class="multiselect-custom custom-title-multiselect" :canClear="false"
                :closeOnSelect="true" :object="true" disabled noOptionsText="No Result" :canDeselect="false" />
            </div>
            <div class="col-lg-8 col-md-8 col-12">
              <input type="text" v-model="profileupdate.mobileno" class="form-control" id="formcontactInput"
                placeholder="Mobile No" autocomplete="off" disabled maxlength="10" @keypress="onlyNumber" />
            </div>
          </div>
          <div class="custom-error" v-if="v$.profileupdate.mobileno.$error">
            {{ v$.profileupdate.mobileno.$errors[0].$message }}
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!disableInputBool">
      <div class="col-lg-12 col-md-12 col-12 text-center">
        <button type="button" :disabled="disableInputBool" @click="profileupdatebtn()"
          class="btn reset-password-submit-btn">
          <span v-if="!showsaveloader">Save</span>
          <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showsaveloader">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-3">
        <button class="btn btn-link profile-reset-password-btn" @click="resetpasswordModalOpen()">
          Change Password
        </button>
      </div>
    </div>
  </div>
  <div class="modal-mask" v-if="resetpasswordmodalstatus">
    <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Change Password</h5>
          <button type="button" class="btn-close" @click="resetpasswordModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <div class="custom-form-group">
                    <label for="formCurrentPasswordInput" class="form-label">
                      Current Password<span class="text-danger">*</span></label>
                    <div class="input-group">
                      <input class="form-control custom-input-password" placeholder="Enter Current Password"
                        aria-label="Password" v-model="passwordupdate.currentpassword" :type="currentPasswordVisibility"
                        maxlength="12" aria-describedby="formCurrentPasswordInput" autocomplete="off" />
                      <span class="input-group-text" id="formPasswordInput">
                        <button @click="showCurrentPasswordType()" v-if="currentPasswordVisibility == 'password'"
                          class="btn btn-link login-password-type">
                          <i class="pi pi-eye-slash"></i>
                        </button>
                        <button @click="hideCurrentPasswordType()" v-if="currentPasswordVisibility == 'text'"
                          class="btn btn-link login-password-type">
                          <i class="pi pi-eye"></i>
                        </button>
                      </span>
                    </div>
                    <div class="custom-error" v-if="v$.passwordupdate.currentpassword.$error">
                      {{ v$.passwordupdate.currentpassword.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="custom-form-group">
                    <label for="formNewPasswordInput" class="form-label">
                      New Password<span class="text-danger">*</span></label>
                    <div class="input-group">
                      <input class="form-control custom-input-password" placeholder="Enter New Password"
                        aria-label="Password" v-model="passwordupdate.newpassword" :type="newPasswordVisibility"
                        maxlength="12" aria-describedby="formNewPasswordInput" autocomplete="off" />
                      <span class="input-group-text" id="formPasswordInput">
                        <button @click="showNewPasswordType()" v-if="newPasswordVisibility == 'password'"
                          class="btn btn-link login-password-type">
                          <i class="pi pi-eye-slash"></i>
                        </button>
                        <button @click="hideNewPasswordType()" v-if="newPasswordVisibility == 'text'"
                          class="btn btn-link login-password-type">
                          <i class="pi pi-eye"></i>
                        </button>
                      </span>
                    </div>
                    <div class="custom-error" v-if="v$.passwordupdate.newpassword.$error">
                      {{ v$.passwordupdate.newpassword.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="custom-form-group">
                    <label for="formConfirmPasswordInput" class="form-label">
                      Confirm New Password<span class="text-danger">*</span></label>
                    <div class="input-group">
                      <input class="form-control custom-input-password" placeholder="Confirm Password"
                        aria-label="Password" v-model="passwordupdate.confirmpassword" :type="confirmPasswordVisibility"
                        maxlength="12" aria-describedby="formConfirmPasswordInput" autocomplete="off" />
                      <span class="input-group-text" id="formPasswordInput">
                        <button @click="showConfirmPasswordType()" v-if="confirmPasswordVisibility == 'password'"
                          class="btn btn-link login-password-type">
                          <i class="pi pi-eye-slash"></i>
                        </button>
                        <button @click="hideConfirmPasswordType()" v-if="confirmPasswordVisibility == 'text'"
                          class="btn btn-link login-password-type">
                          <i class="pi pi-eye"></i>
                        </button>
                      </span>
                    </div>
                    <div class="custom-error" v-if="v$.passwordupdate.confirmpassword.$error">
                      {{ v$.passwordupdate.confirmpassword.$errors[0].$message }}
                    </div>
                    <div v-show="this.passwordupdate.currentpassword != '' && this.passwordupdate.newpassword != ''">
                      <div class="custom-error" v-if="v$.passwordupdate.passwarderror.$error">{{
                        v$.passwordupdate.passwarderror.$errors[0].$message }}</div>
                    </div>
                    <div v-show="this.passwordupdate.confirmpassword != '' && this.passwordupdate.newpassword != ''">
                      <div class="custom-error" v-if="v$.passwordupdate.passconfirmerror.$error">
                        {{ v$.passwordupdate.passconfirmerror.$errors[0].$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <button type="button" class="btn reset-password-submit-btn" @click="resetpasswordsubmit()">
                    <span v-if="!showbtnloader">Update</span>
                    <div class="
                          spinner-border
                          text-light
                          custom-spinner-loader-btn
                        " role="status" v-if="showbtnloader"></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, helpers, requiredIf, minLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../service/ApiService";

export default {
  data() {
    return {
      v$: useValidate(),
      profileupdate: {
        title: { value: 1, label: "Mr" },
        firstname: "",
        middlename: "",
        lastname: "",
        email: "",
        countrycode: { value: 101, label: "+91" },
        mobileno: "",
        profileimage: [],
        userProfileImage: '',
        uploadprofile: '',
      },
      titleList: [],

      countryCodeList: [],
      resetpasswordmodalstatus: false,
      disableInputBool: true,
      disableBtnBool: false,
      errStatus: false,
      errors: "",
      profilefilename: null,
      editRowId: '',

      errorprofileimage: '',
      passwordupdate: {
        currentpassword: "",
        newpassword: "",
        confirmpassword: "",
      },
      currentPasswordVisibility: "password",
      newPasswordVisibility: "password",
      confirmPasswordVisibility: "password",
      showbtnloader: false,
      submitted: false,
      errormsg: "",
      showsaveloader: false,
      user_info: '',
      client_info: '',
      hoststorageimgpath: '',
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },


  mounted() {
    if (localStorage.user) {
      var user_info = JSON.parse(localStorage.user);
      this.editRowId = user_info.ad1;
      this.user_title_fk = user_info.ad4;
      this.user_title_value = user_info.ad5;
      this.profileupdate.title = {
        label: this.user_title_value,
        value: this.user_title_fk,
      };
      this.profileupdate.firstname = user_info.ad6;
      this.profileupdate.middlename = user_info.ad7;
      this.profileupdate.lastname = user_info.ad8;
      this.profileupdate.email = user_info.ad18;
      this.profileupdate.mobileno = user_info.ad15;
      this.profileupdate.userProfileImage = user_info.ad22;
      this.client_info = JSON.parse(localStorage.client_info);
        this.hoststorageimgpath = this.client_info.maa24;
    }
    // this.getcountrycode();
    this.getalltitle();
  },
  validations() {
    return {
      profileupdate: {
        firstname: {
          required: helpers.withMessage("Please enter first name", required),
        },
        lastname: {
          required: helpers.withMessage("Please enter last name", required),
        },
        // email: {
        //   required: helpers.withMessage("Please enter email id", required), email: helpers.withMessage('Email address is invalid', email),
        // },
        mobileno: {
          required: helpers.withMessage("Please enter contact number", required),
        },
      },


      passwordupdate: {
        currentpassword: {
          required: helpers.withMessage("Please enter current password", required)
        },
        newpassword: {
          required: helpers.withMessage("Please enter new password", required),
          minLength: helpers.withMessage(
            "Password should be min 6 length",
            minLength(6)
          ),
        },
        confirmpassword: {
          required: helpers.withMessage("Please enter confirm password", required),
          minLength: helpers.withMessage(
            "Password should be min 6 length",
            minLength(6)
          ),
        },
        passwarderror: {
          requiredIf: helpers.withMessage(
            "Old and new passwords must be different",
            requiredIf(
              this.passwordupdate.currentpassword == this.passwordupdate.newpassword
            )
          ),
        },
        passconfirmerror: {
          requiredIf: helpers.withMessage(
            "New & confirm passward must be same",
            requiredIf(
              this.passwordupdate.confirmpassword != this.passwordupdate.newpassword
            )
          ),
        }
      }

    };
  },
  watch: {
    currentpassword() {
      this.resetpasswordsubmit();
    },
    newpassword() {
      this.resetpasswordsubmit();
    },
    confirmpassword() {
      this.resetpasswordsubmit();
    },
  },
  methods: {
    getcountrycode(e) {
      this.ApiService.getcountrycode(e).then((data) => {
        if (data.status == 200) {
          this.countryCodeList = data.data;
        }
      });
    },
    getalltitle(e) {
      this.ApiService.getalltitle(e).then((data) => {
        if (data.status == 200) {
          this.titleList = data.data;
        }
      });
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    profileeditbtn() {
      this.v$.$validate();
      this.disableInputBool = false;
      this.disableBtnBool = true;
    },
    logout() {
      this.ApiService.logout().then((data) => {
        if (data.status == 200) {
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          setTimeout(() => {
            this.v$.$reset();
          }, 0);
        } else {
          var errorMsg = data.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },
    profileupdatebtn() {
      this.submitted = true;
      this.showsaveloader = true;
      this.errormsg = '';
      this.v$.profileupdate.$validate();
      var formData = new FormData();
      formData.append('ad1', this.editRowId);
      formData.append('ad4', this.profileupdate.title.value);
      formData.append('ad5', this.profileupdate.title.label);
      formData.append('ad6', this.profileupdate.firstname);
      if (this.profileupdate.middlename) {
        formData.append('ad7', this.profileupdate.middlename);
      }
      formData.append('ad8', this.profileupdate.lastname);
      formData.append('ad18', this.profileupdate.email);
      formData.append('ad15', this.profileupdate.mobileno);
      if (this.profileupdate.uploadavtimg) {
        formData.append('userProfileImage', this.profileupdate.uploadavtimg);
      }
      if (!this.v$.profileupdate.$error) {
        this.disableInputBool = true;
        this.disableBtnBool = false;
        this.ApiService.updateUserProfile(formData).then((data) => {
          if (data.status == 200) {
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.showsaveloader = false;
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.logout();
          } else {
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showsaveloader = false;
          }
        });
      } else {
        this.$error;
      }
    },
    resetpasswordModalOpen() {
      this.resetpasswordmodalstatus = true;
    },
    resetpasswordModalClose() {
      this.resetpasswordmodalstatus = false;
    },
    isLetterWithOutSpace(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    handleUploadVoterProfile() {
      var file = this.$refs.uploadavtimg.files[0];
      let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (!file.size > 2048) {
        this.errorprofileimage = 'File size cannot exceed more than 2MB';
      } else if (!file.name.match(allowedExtensions)) {
        this.errorprofileimage = 'Invalid file type';
      } else {
        this.errorprofileimage = '';
        this.profileupdate.uploadavtimg = this.$refs.uploadavtimg.files[0];
      }
    },
    resetpasswordsubmit() {
      this.v$.passwordupdate.$validate();
      let fields = {};
      fields["ad1"] = this.editRowId;
      fields["old_password"] = this.passwordupdate.currentpassword;
      fields["password"] = this.passwordupdate.newpassword;
      fields["password_confirmation"] = this.passwordupdate.confirmpassword;
      if (!this.v$.passwordupdate.$error && this.passwordupdate.currentpassword != this.passwordupdate.newpassword) {
        this.showbtnloader = true;
        this.ApiService.resetPassword(fields).then((items) => {
          if (items.status === 200) {
            this.showbtnloader = false;
            var successMsg = items.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.passwordupdate.currentpassword = '';
            this.passwordupdate.newpassword = '';
            this.passwordupdate.confirmpassword = '';
            this.resetpasswordmodalstatus = false;
          } else {
            var errorMsg = items.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.resetpasswordmodalstatus = true;
            this.showbtnloader = false;
          }
        });
      } else {
        this.$error;
      }
    },
    showCurrentPasswordType() {
      this.currentPasswordVisibility = "text";
    },
    hideCurrentPasswordType() {
      this.currentPasswordVisibility = "password";
    },
    showNewPasswordType() {
      this.newPasswordVisibility = "text";
    },
    hideNewPasswordType() {
      this.newPasswordVisibility = "password";
    },
    showConfirmPasswordType() {
      this.confirmPasswordVisibility = "text";
    },
    hideConfirmPasswordType() {
      this.confirmPasswordVisibility = "password";
    },
  },
};
</script>
<style scoped>
.profile-reset-password-btn {
  letter-spacing: 0px;
  color: #3362db;
  font-size: 14px;
  font-family: "AcuminPro-Regular";
  padding: 0;
  text-decoration: none;
}

.profile-reset-password-btn:focus-visible {
  outline: 0;
}

.profile-reset-password-btn:focus {
  box-shadow: none;
}

.custom-image-upload>input {
  display: none;
}

.custom-image-upload {
  position: absolute;
  right: 0;
  bottom: 0;
}

.reset-password-submit-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 19px;
  font-family: "AcuminPro-Regular";
  width: 80px;
}

.reset-password-submit-btn:focus {
  box-shadow: none;
}

.upload-image-status-outer {
  padding: 5px 15px;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  display: inline-block;
}

.upload-image-status-outer .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.upload-image-status-outer .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}
</style>